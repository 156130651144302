import { createRouter, createWebHashHistory } from 'vue-router'
import routes from "./routes";
import { getToken } from '@/utils/auth'


const whiteList = ['/login',]

const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
	},
	
]
const router = createRouter({
  history: createWebHashHistory(),
  routes:[...constantRoutes,...routes]
})
router.beforeEach((to, from, next) => { 
	const hasToken = getToken()
	const userInfo = sessionStorage.getItem('userInfo')
	if (hasToken && userInfo) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
    }
  }
})

export default router
