/*
 * @Author: wayne
 * @Date: 2024-06-06 09:12:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-24 09:38:38
 * @Description: 请填写简介
 */
import Layout from '@/layout'
export default [
	{
		path: '/',
		menu: true,
		name: 'layout',
		component:Layout,
		redirect:'/consulting',
		children: [
			{
				path: 'consulting',
				name: 'consulting',
				component: () => import('@/views/newsManage/index.vue'),
				meta: {
					menu: true,
					title:'资讯管理',
				}
			},
			{
				path: 'information',
				name: 'information',
				component: () => import('@/views/informationManage/index.vue'),
				meta: {
					menu: true,
					title:'咨询管理',
				}
			},
			{
				path: 'trial',
				name: 'trial',
				component: () => import('@/views/trialManage/index.vue'),
				meta: {
					menu: true,
					title:'试用管理',
				}
			},
		]
	}
]
